<template>
 <div class="my-box" v-loading="loading">
  <!-- 功能区域 -->
  <el-row>
   <el-card shadow="always" class="topS">
    <el-form ref="seekData" label-width="80px">
     <el-row :gutter="10">
      <el-col :span="3">
       <el-button @click="addBtn">新增</el-button>
       <el-button @click="batchesDelete">批量删除</el-button>
      </el-col>
      <el-col :span="3">
       <el-form-item label="用户名称">
        <el-input placeholder="请输入内容" v-model="seekData.username" clearable class="my-input"></el-input>
       </el-form-item>
      </el-col>
      <el-col :span="3">
       <el-form-item label="联系方式">
        <el-input placeholder="请输入内容" v-model="seekData.phone" clearable class="my-input"></el-input>
       </el-form-item>
      </el-col>
      <el-col :span="3">
       <el-form-item label="状态" label-width="50px">
        <el-select v-model="seekData.status" placeholder="请选择" clearable >
         <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
       </el-form-item>
      </el-col>
      <el-col :span="6">
       <el-form-item label="有效期" label-width="60px">
        <el-date-picker v-model="createTime" type="daterange" clearable  range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd"></el-date-picker>
       </el-form-item>
      </el-col>
      <el-col :span="6" class="reset-button">
       <el-button type="primary" @click="handleSearch">查询</el-button>
       <el-button @click="reset">重置</el-button>
      </el-col>
     </el-row>
    </el-form>

   </el-card>
  </el-row>
  <!-- 内容区域 -->
  <el-row>
   <el-card shadow="always">
    <div class="my-header-table">
     <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" :height="height" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="40"></el-table-column>
      <el-table-column prop="username" label="用户名称"></el-table-column>
      <el-table-column prop="phone" label="联系方式"></el-table-column>
      <el-table-column prop="clientId" label="Appkey"></el-table-column>
      <el-table-column prop="clientSecret" label="appSecret"></el-table-column>
      <el-table-column label="有效期" width="200">
       <template slot-scope="scope">
        <span>{{ getTime(scope.row.expireTime) }}</span>
       </template>
      </el-table-column>
      <el-table-column prop="status" label="过期状态" width="100">
       <template slot-scope="scope">
        <el-tag v-if="scope.row.expireStatus==1">未过期</el-tag>
        <el-tag v-else type="info">已过期</el-tag>
       </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" width="100">
       <template slot-scope="scope">
        <el-tag v-if="scope.row.status==1">启用</el-tag>
        <el-tag v-else type="info">停用</el-tag>
       </template>
      </el-table-column>
      <!-- <el-table-column prop="description" label="描述" show-overflow-tooltip></el-table-column> -->
      <!-- 操作按钮列 -->
      <el-table-column label="操作" width="150">
       <template slot-scope="scope">
        <!-- 编辑按钮 -->
        <el-button v-if="showupdate!=-1" type="primary"  size="mini" dialogFormVisible @click="handleEdit(scope.row)">修改</el-button>
        <!-- 删除按钮 -->
        <el-button v-if="showdelete!=-1" size="mini" type="danger"  @click="handleDelete(scope.row)">删除</el-button>
       </template>
      </el-table-column>
     </el-table>
     <el-pagination background layout="prev, pager, next, sizes, total, jumper" :page-sizes="[10, 20, 50, 100]" :page-size="seekData.pageSize" :current-page="seekData.pageNum" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange"></el-pagination>
    </div>
   </el-card>
  </el-row>
  <!-- 编辑的弹框 -->
  <el-dialog title="编辑用户" :visible.sync="dialogFormVisible2">
   <el-form :rules="myrules" label-width="80px" :ref="editData" :model="editData">
    <el-row :gutter="10">
     <el-col :span="12">
      <el-form-item label="用户名称" prop="username">
       <el-input placeholder="请输入用户名称" v-model="editData.username" clearable class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="手机号" prop="phone">
       <el-input placeholder="请输入联系电话" v-model="editData.phone" clearable class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="Appkey">
       <el-input placeholder="" v-model="editData.clientId" disabled class="my-input"></el-input>
      </el-form-item>
     </el-col>

     <el-col :span="12">
      <el-form-item label="appSecret">
       <el-input placeholder="" v-model="editData.clientSecret" disabled class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="15">
      <el-form-item label="有效期" prop="expireTime">
       <el-date-picker v-model="editData.expireTime" type="date" range-separator="-" format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
    </el-row>
    <el-row>
     <el-col :span="16">
      <el-form-item label="状态" prop="status">
       <div>
        <el-radio v-model="editData.status" label="1" border :disabled=onoff>启用</el-radio>
        <el-radio v-model="editData.status" label="0" border :disabled=onoff>停用</el-radio>
       </div>
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="dialogFormVisible2=false">取 消</el-button>
    <el-button type="primary" @click="confirmEditD(editData)" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>

  <!-- 新增的弹框 -->
  <el-dialog title="新增用户" :visible.sync="dialogFormVisible">
   <el-form label-position="right" label-width="90px" :rules="myrules" ref="addform" :model="addform">
    <el-row :gutter="10">
     <el-col :span="12">
      <el-form-item label="用户名称" prop="username">
       <el-input placeholder="请输入用户名称" v-model="addform.username" clearable class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="12">
      <el-form-item label="手机号" prop="phone">
       <el-input placeholder="请输入联系电话" v-model="addform.phone" clearable class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="15">
      <el-form-item label="有效期" prop="expireTime">
       <el-date-picker v-model="addform.expireTime" type="date" range-separator="-" value-format="yyyy-MM-dd" format="yyyy 年 MM 月 dd 日" placeholder="选择日期">
       </el-date-picker>
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="abrogateAdd">取 消</el-button>
    <el-button type="primary" @click="confirmAdd('addform')" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
  <!-- 重置密码的弹框 -->
  <el-dialog title="重置密码" :visible.sync="dialogFormVisible3">
   <el-form label-position="right" label-width="250px" :rules="myrules" :ref="passwordData" :model="passwordData">
    <el-row :gutter="10">
     <el-col :span="24">
      <el-form-item label="账号">
       <el-input placeholder="请输入内容" v-model="passwordData.username" clearable disabled class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="姓名" prop="fullName">
       <el-input placeholder="请输入真实姓名" v-model="passwordData.fullName" clearable disabled class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="密码" prop="password">
       <el-input placeholder="请输入" v-model="passwordData.password" show-password class="my-input"></el-input>
      </el-form-item>
     </el-col>
     <el-col :span="24">
      <el-form-item label="确认密码" prop="password4">
       <el-input placeholder="请再次输入密码" v-model="passwordData.password2" show-password class="my-input"></el-input>
      </el-form-item>
     </el-col>
    </el-row>
   </el-form>
   <div slot="footer" class="dialog-footer">
    <el-button @click="abrogateDassword">取 消</el-button>
    <el-button type="primary" @click="confirmDassword(passwordData)" :loading="loading">确 定</el-button>
   </div>
  </el-dialog>
  <!-- 查看详情弹窗 -->
  <el-dialog title="查看用户详情" :visible.sync="dialogTableVisible4">
   <div>
    <el-row>
     <el-col :span="12">用户名：{{gridData.username}}</el-col>
     <el-col :span="12">角色：{{gridData.roleName}}</el-col>
    </el-row>
    <el-row>
     <el-col :span="12">品牌：{{gridData.brand}}</el-col>
     <el-col :span="12">门店：{{gridData.store}}</el-col>
    </el-row>
    <el-row>
     <el-col :span="12">真实姓名：{{gridData.fullName}}</el-col>
     <el-col :span="12">邮箱：{{gridData.email}}</el-col>
    </el-row>
    <el-row>
     <el-col :span="12">电话号码：{{gridData.mobile}}</el-col>
     <el-col :span="12">座机号码：{{gridData.oph}}</el-col>
    </el-row>
    <el-row>
     <el-col :span="12">创建时间：{{gridData.createTime}}</el-col>
     <el-col :span="12">最近登陆时间：{{gridData.lastLoginTime}}</el-col>
    </el-row>
   </div>
  </el-dialog>
 </div>
</template>
<script>
import {
 getOauthUser,
 addOauth,
 updateOauth,
 delOauths,
 usernameVerify,
 getRoleSelect,
 getHotelSelect,
 getBuildingSelect,
 delUser,
 getRights,
 getBrandSelect,
 editUser,
 getHotelSeek,
} from "@/api";
import { regionData, CodeToText } from "element-china-area-data";
export default {
 name: "sqgl",
 data() {
  /* 自定义校验规则 */
  /* 密码自定义校验规则 */
  let Password = (rule, value, callback) => {
   // console.log(value)
   if (value == "") {
    callback(new Error("密码不能为空"));
   } else {
    // let passwordReg = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,10}$/
    let passwordReg = /^(?![^a-zA-Z]+$)(?!\D+$).{6,10}$/;
    if (passwordReg.test(value)) {
     callback();
    } else {
     callback(new Error("至少包含字母和数字，长度 6-10位"));
    }
   }
  };

  var validatePass2 = (rule, value, callback) => {
   // console.log(value)
   if (value === "") {
    callback(new Error("请再次输入密码"));
   } else if (value !== this.addform.password) {
    callback(new Error("两次输入密码不一致!"));
   } else {
    callback();
   }
  };

  var validatePass4 = (rule, value, callback) => {
   if (this.passwordData.password === "") {
    callback(new Error("请再次输入密码"));
   } else if (this.passwordData.password2 !== this.passwordData.password) {
    callback(new Error("两次输入密码不一致!"));
   } else {
    callback();
   }
  };
  /* 姓名自定义校验规则 */
  let dateTime = (rule, value, callback) => {
   if (value === "" || value === null) {
    callback(new Error("日期不能为空"));
   } else {
    callback();
   }
  };
  /* 用户名重复校验 */
  let Verify = (rule, value, callback) => {
   if (value === "" || value === null) {
    callback(new Error("姓名不能为空"));
   } else {
    callback();
   }
  };
  /* 邮箱自定义校验规则 */
  let Email = (rule, value, callback) => {
   let emailReg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
   if (emailReg.test(value)) {
    // 校验通过
    callback();
   } else {
    // 校验不通过
    callback(new Error("请输入正确的邮箱格式"));
   }
  };
  /* 手机号自定义校验规则 */
  let Mobile = (rule, value, callback) => {
   if (value === "") {
    callback(new Error("请输入手机号"));
   } else {
    let mobileReg = /^1(3|4|5|7|8|9)\d{9}$/;
    if (mobileReg.test(value)) {
     callback(); // 校验通过
    } else {
     callback(new Error("请输入正确的手机号")); // 校验不通过
    }
   }
  };
  return {
   height: null,
   onoff: false,
   userJurisdiction: null,
   multipleSelection: [],
   loading: false,
   //查看详情数据
   gridData: {
    fullName: null,
    mobile: null,
    oph: null,
    ssex: null,
    description: null,
    username: null,
    brand: null,
    store: null,
    roleId: null,
    userId: null,
    email: null,
    status: null,
    lastLoginTime: null,
   },
   // 重置密码数据
   passwordData: {
    username: null,
    fullName: null,
    password: "",
    password2: null,
   },
   dialogFormVisible3: false,
   dialogTableVisible4: false,
   // 建筑列表数据
   tableData: null,
   dialogFormVisible2: null,
   dialogFormVisible: null,
   foldData: false,
   dialogVisible: false,
   formLabelWidth: "100px",
   // 查询的数据
   total: null,
   seekData: {
    username: null, //账号
    phone: null,
    status: null,
    expireTimeFrom: null,
    expireTimeTo: null,
    pageSize: 10,
    pageNum: 1,
   },
   createTime: null,
   lastLoginTime: null,
   hotelId: null,
   brandId: null,
   options1: [
    {
     value: "1",
     label: "启用",
    },
    {
     value: "0",
     label: "停用",
    },
   ],
   // 新增
   addform: {
    username: "", //账号
    phone: "", //电话
    expireTime: "",

    // floorName: null,      //楼层名称
    // buildingId: null,    //建筑ID
   },
   brandSelectData: null,
   hotelSelectData: null,
   buildingSelectData: null,
   roleSelectData: null,
   options: regionData,
   selectedOptions: [],
   // 编辑
   editData: {
    phone: null,
    expireTime: "",
    username: null,
    status: null,
   },
   myrules: {
    floorName: [{ required: true, message: "请选择", trigger: "change" }],
    brandId: [{ required: true, message: "请选择", trigger: "change" }],
    storeId: [{ required: true, message: "请选择", trigger: "change" }],
    roleId: [{ required: true, message: "请选择", trigger: "change" }],
    ssex: [{ required: true, message: "请选择", trigger: "change" }],
    status: [{ required: true, message: "请选择", trigger: "change" }],
    password: [
     { required: true, validator: Password, trigger: ["blur", "change"] },
    ],
    password2: [
     { required: true, validator: validatePass2, trigger: ["blur", "change"] },
    ],
    password4: [
     { required: true, validator: validatePass4, trigger: ["blur", "change"] },
    ],
    expireTime: [{ required: true, validator: dateTime, trigger: ["blur"] }],
    username: [{ required: true, validator: Verify, trigger: "blur" }],

    phone: [{ required: true, validator: Mobile, trigger: ["blur", "change"] }],
   },

   defaultProps: {
    children: "children",
    label: "title",
   },
  };
 },
 beforeMount() {
  let h = document.documentElement.clientHeight || document.body.clientHeight;
  this.height = h - 250;
 },
 computed: {
  // 权限
  showadd: function () {
   return this.userJurisdiction.indexOf("user:add");
  },
  showdelete: function () {
   return this.userJurisdiction.indexOf("user:delete");
  },
  showupdate: function () {
   return this.userJurisdiction.indexOf("user:update");
  },
  showreset: function () {
   return this.userJurisdiction.indexOf("user:reset");
  },
  // dv: function windowHeight () {
  //   var de = document.documentElement;
  //   return (self.innerHeight || (de && de.offsetHeight) || document.body.offsetHeight) - 306;
  // }
 },
 // 注册表格组件
 components: {},
 created() {
  this.userJurisdiction = this.$store.state.roleMenu;
  // 调用初始化表格数据方法
  this.initList(this.seekData);
  this.initialize();
 },
 methods: {
  // 初始化表格数据
  initList(obj) {
   getOauthUser(obj).then((res) => {
    console.log(res);
    if (res.status === 200) {
     let data = res.data.data;
     this.tableData = data.rows;
     this.total = data.total;
     if (this.seekData.pageNum > 1 && data.data.rows == false) {
      this.seekData.pageNum = this.seekData.pageNum - 1;
      this.initList(this.seekData);
     }
    }
   });
  },

  /* 初始化下拉框 */
  // 获取品牌下拉框
  initialize() {
   // let userBrandId = JSON.parse(sessionStorage.getItem('user')).brandId;
   getBrandSelect().then((res) => {
    if (res.status === 200) {
     this.brandSelectData = res.data.data;
     // console.log(res)
    }
   });
  },
  // 获取门店下拉框
  selectOne(id) {
   this.addform.storeId = null;
   this.addform.roleId = null;
   this.editData.storeId = null;
   this.editData.roleId = null;
   this.seekData.store = null;
   this.roleSelectData = null;
   getHotelSelect(id).then((res) => {
    // console.log(res)
    if (res.data.data) {
     this.hotelSelectData = res.data.data;
    } else {
     this.$message({
      message: "该品牌下没有门店",
      type: "warning",
     });
    }
   });
  },
  // 获取建筑下拉框
  selectTwo(id) {
   // console.log(id)
   // getBuildingSelect(id).then((res) => {
   //   // console.log(res)
   //   if (res.data) {
   //     this.buildingSelectData = res.data
   //   } else {
   //     this.$message({
   //       message: '该门店下没有建筑',
   //       type: 'warning'
   //     });
   //   }
   // })
   this.addform.roleId = null;
   this.editData.roleId = null;
   if (this.dialogFormVisible) {
    this.getRolesData(this.addform.brandId, id);
   } else {
    this.getRolesData(this.editData.brandId, id);
   }
  },

  /* 展开搜索头部 */
  fold() {
   this.foldData = !this.foldData;
   if (this.foldData) {
    this.initialize();
   }
  },

  /* 新增弹窗 */
  // 获取品牌下拉框
  addBtn() {
   this.dialogFormVisible = true;
   this.hotelSelectData = this.roleSelectData = null;
  },
  // 获取角色下拉框
  getRolesData(brandId, storeId) {
   getRoleSelect(brandId, storeId).then((res) => {
    this.roleSelectData = res.data.data;
   });
  },

  // 确定按钮
  confirmAdd(formName) {
   // addform.province = selectedOptions
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     this.addform.expireTime = this.addform.expireTime + " 23:59:59";
     addOauth(this.addform)
      .then((res) => {
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success(res.data.message);
        this.dialogFormVisible = false;
        this.initList(this.seekData);
        this.addform.username = ""; //用户名称
        this.addform.expireTime = ""; //结束时间
        this.addform.phone = ""; //联系方式
       } else {
        this.$message.error(res.data.message);
       }
      })
      .catch((err) => {
       console.log(err);
      });
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  // 取消按钮
  abrogateAdd() {
   this.dialogFormVisible = false;
  },

  // 用户删除
  handleDelete(row) {
   this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
   })
    .then(() => {
     // 点击确定执行的操作
     console.log(row)
     delOauths(row.auId).then((res) => {
      console.log(res)
      if (res.data.code == "000000") {
       this.$message.success("删除成功");
       this.initList(this.seekData);
       // this.reset()
      } else {
       this.$message.error("删除失败");
      }
     });
    })
    .catch(() => {
     this.$message({
      type: "info",
      message: "已取消删除",
     });
    });
  },
  // 批量删除
  batchesDelete() {
   if (this.multipleSelection.length != 0) {
    // 把要删除的用户ID以字符串拼接
    let number = "";
    for (let i = 0; i < this.multipleSelection.length; i++) {
     const element = this.multipleSelection[i];
     number += element.auId + ",";
    }
    number = number.slice(0, number.length - 1); //去掉最后的逗号
    this.$confirm("此操作将永久删除用户, 是否继续?", "提示", {
     confirmButtonText: "确定",
     cancelButtonText: "取消",
     type: "warning",
    })
     .then(() => {
      this.loading = true;
      delOauths(number).then((res) => {
       this.loading = false;
       if (res.data.code == "000000") {
        this.$message.success("删除成功");
        this.initList(this.seekData);
        // this.reset()
       } else {
        this.$message.error("删除失败");
       }
      });
     })
     .catch(() => {
      this.loading = false;
      this.$message({
       type: "info",
       message: "已取消删除",
      });
     });
   } else {
    this.$message.warning("请先选择要删除的数据");
   }
  },

  handleSelectionChange(val) {
   this.multipleSelection = val;
   console.log(val);
  },

  // 编辑用户
  handleEdit(index, row) {
   this.editData.phone = index.phone;
   this.editData.clientSecret = index.clientSecret;
   this.editData.clientId = index.clientId;
   this.editData.username = index.username;
   this.editData.expireTime = this.getTime(index.expireTime);
   this.editData.storeId = index.storeId;
   this.editData.status = index.status;
   this.editData.auId = index.auId;
   this.dialogFormVisible2 = true;
  },
  getTime(time) {
   var date = new Date(time);
   var year = date.getFullYear();
   var month = date.getMonth() + 1; //getMonth()获取的月权份从0开始，0~11,所以要加一
   var day = date.getDate();
   return (
    year +
    "-" +
    (month > 9 ? month : "0" + month) +
    "-" +
    (day > 9 ? day : "0" + day)
   );
  },
  // 编辑用户确认
  confirmEditD(formName) {
   // console.log(this.editData);
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     this.editData.expireTime = this.editData.expireTime + " 23:59:59";
     updateOauth(this.editData).then((res) => {
      this.loading = false;
      if (res.data.code == "000000") {
       this.$message.success(res.data.message);
       this.editData.brand = null;
       this.editData.store = null;
       this.editData.roleId = null;
       this.initList(this.seekData);
       // this.reset()
       this.dialogFormVisible2 = false;
      } else {
       this.$message.error(res.data.message);
      }
     });
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  // 查询按钮
  handleSearch() {
   if (this.createTime) {
    this.seekData.expireTimeTo = this.createTime[1] + " 23:59:59";
    this.seekData.expireTimeFrom = this.createTime[0] + " 00:00:00";
   } else {
    this.seekData.expireTimeTo = null;
    this.seekData.expireTimeFrom = null;
   }
   // console.log(this.seekData)
   this.seekData.pageNum = 1;
   if (this.seekData) {
    this.loading = true;
    getOauthUser(this.seekData).then((res) => {
     // console.log(res)
     if (res.status === 200) {
      let data = res.data.data.data;
      this.tableData = data.rows;
      this.total = data.total;
      this.loading = false;
     }
    });
   } else {
    this.initList(this.seekData);
   }
  },
  // 重置按钮
  reset() {
   this.seekData.username = null; //账号
   this.seekData.phone = null;
   this.createTime = null;
   this.seekData.expireTimeTo = null;
   this.seekData.expireTimeFrom = null;
   this.seekData.pageNum = 1;
   this.hotelSelectData = null;
   this.initList(this.seekData);
  },
  // 重置密码按钮
  resetPassword() {
   if (this.multipleSelection.length == 1) {
    this.passwordData.username = this.multipleSelection[0].username;
    this.passwordData.fullName = this.multipleSelection[0].fullName;
    this.dialogFormVisible3 = true;
   } else {
    this.$message.warning("必须且只能选择一个要重置密码的用户");
   }
  },
  confirmDassword(formName) {
   this.$refs[formName].validate((valid) => {
    if (valid) {
     this.loading = true;
     editpassword(this.passwordData).then((res) => {
      this.loading = false;
      if (res.data.code == "000000") {
       this.dialogFormVisible3 = false;
       this.$message({
        message: res.data.message,
        type: "success",
       });
      }
     });
    } else {
     console.log("error submit!!");
     return false;
    }
   });
  },
  abrogateDassword() {
   this.dialogFormVisible3 = false;
  },
  //查看详情按钮
  handleDetails(row) {
   this.gridData = row;
   console.log(this.gridData);
   this.dialogTableVisible4 = true;
  },
  //分页
  handleCurrentChange(cpage) {
   this.seekData.pageNum = cpage;
   this.initList(this.seekData);
  },
  handleSizeChange(psize) {
   this.seekData.pageSize = psize;
   this.seekData.pageNum = 1;
   this.initList(this.seekData);
  },
 },
};
</script>
<style lang="scss" scoped>
.my-box {
 .my-input {
  max-width: 215px;
 }
 .el-row {
  margin-bottom: 10px;
  &:last-child {
   margin-bottom: 0;
  }
 }
 .el-col {
  border-radius: 4px;
 }

 .grid-content {
  border-radius: 4px;
  height: 36px;
  white-space: nowrap;
  .el-input__inner {
   max-width: 190px;
  }
  span {
   font-size: 14px;
  }
 }
 
}
</style>
